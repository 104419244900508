var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"steps-transparent mb-5",attrs:{"color":"#296db4","title":null,"subtitle":null,"finish-button-text":"Submit","back-button-text":"Previous","skip-button-text":"Skip"},on:{"on-complete":_vm.addCompany}},[_c('tab-content',{attrs:{"title":"Company Details","icon":"feather icon-file-text"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v("Company Details")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter Your Company Details. ")])])],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Company Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Company Name","rules":{
										required: true,
										regex: RegExp(/^[a-zA-Z\ ]*$/),
										max: 30,
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"company_name","placeholder":"Enter Company Name","maxlength":"30"},model:{value:(_vm.companyData.company_name),callback:function ($$v) {_vm.$set(_vm.companyData, "company_name", $$v)},expression:"companyData.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"company_email_id","placeholder":"Enter Company Email","autocomplete":"off","maxlength":"50"},model:{value:(_vm.companyData.company_email_id),callback:function ($$v) {_vm.$set(_vm.companyData, "company_email_id", $$v)},expression:"companyData.company_email_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"mb-2 mt-2 top-header"},[_vm._v("Address Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Address"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":"company_address","placeholder":"Enter Company Address","rows":"3","maxlength":"100"},model:{value:(_vm.companyData.company_address),callback:function ($$v) {_vm.$set(_vm.companyData, "company_address", $$v)},expression:"companyData.company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Country"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('v-select',{attrs:{"options":_vm.countryList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getState},model:{value:(_vm.companyData.company_selectedCountry),callback:function ($$v) {_vm.$set(_vm.companyData, "company_selectedCountry", $$v)},expression:"companyData.company_selectedCountry"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" State"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" State Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.stateList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getCity},model:{value:(_vm.companyData.company_selectedState),callback:function ($$v) {_vm.$set(_vm.companyData, "company_selectedState", $$v)},expression:"companyData.company_selectedState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('v-select',{attrs:{"options":_vm.cityList,"reduce":function (e) { return e.id; },"label":"name"},model:{value:(_vm.companyData.company_selectedCity),callback:function ($$v) {_vm.$set(_vm.companyData, "company_selectedCity", $$v)},expression:"companyData.company_selectedCity"}})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Assign Owner","icon":"feather icon-map-pin"}},[_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"mb-5"},[_c('h3',{staticClass:"top-header mb-2"},[_vm._v("Owner Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Name","rules":{
											required: true,
											regex: RegExp(/^[a-zA-Z\ ]*$/),
											max: 30,
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","type":"text","placeholder":"Enter Name"},model:{value:(_vm.companyData.name),callback:function ($$v) {_vm.$set(_vm.companyData, "name", $$v)},expression:"companyData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","name":"email","placeholder":"Email"},model:{value:(_vm.companyData.email),callback:function ($$v) {_vm.$set(_vm.companyData, "email", $$v)},expression:"companyData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Username"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Username","rules":{
											required: true,
											regex: RegExp(/^[A-Za-z0-9 ]+$/),
											max: 25,
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","name":"user_name","placeholder":"Enter Username"},model:{value:(_vm.companyData.user_name),callback:function ($$v) {_vm.$set(_vm.companyData, "user_name", $$v)},expression:"companyData.user_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Status ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('div',{staticClass:"d-flex mt-1"},[_c('b-form-radio',{staticClass:"mr-1",attrs:{"name":"companyData.status","value":"Active"},model:{value:(_vm.companyData.status),callback:function ($$v) {_vm.$set(_vm.companyData, "status", $$v)},expression:"companyData.status"}},[_vm._v("Active")]),_c('b-form-radio',{attrs:{"name":"companyData.status","value":"InActive"},model:{value:(_vm.companyData.status),callback:function ($$v) {_vm.$set(_vm.companyData, "status", $$v)},expression:"companyData.status"}},[_vm._v("Inactive")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{ref:"password",attrs:{"type":"password","name":"password","placeholder":"Enter Password","autocomplete":"new-password"},model:{value:(_vm.companyData.password),callback:function ($$v) {_vm.$set(_vm.companyData, "password", $$v)},expression:"companyData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm Password"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":{ required: true, is: _vm.companyData.password }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","name":"confirm password","placeholder":"Confirm Password","autocomplete":"new-password"},model:{value:(_vm.companyData.cpassword),callback:function ($$v) {_vm.$set(_vm.companyData, "cpassword", $$v)},expression:"companyData.cpassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phone"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Phone","rules":{
											required: true,
											integer: true,
											min: 10,
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"phone","name":"phone_no","placeholder":"Enter Phone No","maxlength":"10"},model:{value:(_vm.companyData.phone_no),callback:function ($$v) {_vm.$set(_vm.companyData, "phone_no", $$v)},expression:"companyData.phone_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-1",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Profile Image ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Image","rules":{
											mimes: ['image/jpeg', 'image/png'],
											size: 2048,
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-row',[_c('b-col',{staticClass:"col-9"},[_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.onFileChange},model:{value:(_vm.companyData.user_image),callback:function ($$v) {_vm.$set(_vm.companyData, "user_image", $$v)},expression:"companyData.user_image"}})],1),_c('b-col',{staticClass:"col-3"},[_c('b-button',{staticClass:"reset-btn",attrs:{"variant":"outline-primary"},on:{"click":_vm.resetImage}},[_vm._v("X")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-card-text',[_vm._v("Allowed JPG, GIF or PNG. Max size of 2 Mb")]),(_vm.url)?_c('div',[_c('div',{staticClass:"profile-image"},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.url,"height":"90"}})])]):_vm._e()],1)],1),_c('h3',{staticClass:"top-header"},[_vm._v("Address Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Address"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"type":"text","name":"address","placeholder":"","rows":"3","maxlength":"100"},model:{value:(_vm.companyData.address),callback:function ($$v) {_vm.$set(_vm.companyData, "address", $$v)},expression:"companyData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Country"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Country Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getState},model:{value:(_vm.companyData.selectedCountry),callback:function ($$v) {_vm.$set(_vm.companyData, "selectedCountry", $$v)},expression:"companyData.selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" State"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"State Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.stateList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getCity},model:{value:(_vm.companyData.selectedState),callback:function ($$v) {_vm.$set(_vm.companyData, "selectedState", $$v)},expression:"companyData.selectedState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"City Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cityList,"reduce":function (e) { return e.id; },"label":"name"},model:{value:(_vm.companyData.selectedCity),callback:function ($$v) {_vm.$set(_vm.companyData, "selectedCity", $$v)},expression:"companyData.selectedCity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }